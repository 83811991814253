
          $env: 'production';
          @import '/var/build/src/styles/utils/index';

        



















.dual-text {
  @include mq(m) {
    justify-content: flex-end;
  }
}

.text {
  ::v-deep {
    p:not(.lead) {
      margin: 2rem 0;
    }

    @include mq(m) {
      p:first-child {
        margin-top: 0;
      }

      p:not(.lead):not(:first-child) {
        margin: 3rem 0;
      }
    }
  }
}

.title {
  margin-bottom: 2rem;

  @include mq(m) {
    margin-right: inside-col(2);
    margin-bottom: 0;

    [class*='dual-text--small-title'] & {
      font-size: 3.6rem;
    }
  }
}

.lead-text {
  margin-bottom: 2rem;

  @include mq(m) {
    margin-bottom: 5rem;
  }
}

.content {
  @include mq(m) {
    flex-basis: inside-col(9);
  }
}

.link {
  margin-top: 2rem;
}
