
          $env: 'production';
          @import '/var/build/src/styles/utils/index';

        







































.scrolling-text {
  overflow: hidden;
  padding: 3rem 0;
  background: $c-white;
  border-top: 0.1rem solid $alto;
  border-bottom: 0.1rem solid $alto;
}

.store {
  color: $tango;
  text-align: center;

  .icon {
    display: block;
    width: 2.4rem;
    height: 2.4rem;
    margin: 0 auto;
    fill: $tango;
  }
}

.text {
  display: flex;
}

.title {
  flex-shrink: 0;
  text-align: center;
  animation: scroll 8s $linear forwards infinite;

  .is-hidden &,
  .text:hover & {
    animation-play-state: paused;
  }
}

.label {
  @include manier;
  @include fluid(
    font-size,
    (
      mob: 25px,
      xxxl: 70px,
    )
  );

  font-weight: 700;
  line-height: 1;
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke: 1px $c-black;

  .link:hover & {
    -webkit-text-fill-color: $c-black;
  }
}

.headline {
  opacity: 0;

  .link:hover & {
    opacity: 1;
  }
}

@keyframes scroll {
  from {
    transform: translateX(0);
  }

  to {
    transform: translateX(-100%);
  }
}
