@mixin scrollbars(
  $size: 6px,
  $foreground-color: $black,
  $background-color: transparent
) {
  // sass-lint:disable-block no-vendor-prefixes
  // For Google Chrome
  &::-webkit-scrollbar {
    position: absolute;
    width: $size;
    height: $size;
  }

  &::-webkit-scrollbar-thumb {
    background: $foreground-color;
    border-radius: $size;
  }

  // For Internet Explorer
  body {
    scrollbar-face-color: $foreground-color;
    scrollbar-track-color: $background-color;
  }
}

@mixin get-all-space {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

@mixin aspect-ratio($width, $height) {
  position: relative;

  &::before {
    content: '';
    display: block;
    width: 100%;
    padding-top: ($height / $width) * 100%;
  }

  > * {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
}

@mixin image-fit($fit: cover, $position: null) {
  @include get-all-space;

  object-fit: $fit;

  @if $position {
    object-position: $position;
  }
}

@mixin owl($margin: false) {
  > :first-child {
    margin-top: 0;

    @if $margin {
      margin-bottom: $margin;
    }
  }

  > :last-child {
    margin-bottom: 0;
  }

  > * + * {
    margin-top: 0;

    @if $margin {
      margin-bottom: $margin;
    }
  }
}

@mixin fluid($property, $map) {
  $finalmap: ();

  @each $breakpoint, $value in $map {
    $nameToPx: map-get($mq-breakpoints, $breakpoint);

    @if $nameToPx {
      $finalmap: map-merge(
        $finalmap,
        (
          $nameToPx: $value,
        )
      );
    } @else {
      $finalmap: map-merge(
        $finalmap,
        (
          $breakpoint: $value,
        )
      );
    }
  }

  @include poly-fluid-sizing($property, $finalmap);
}

@mixin expand($size) {
  position: relative;

  &::before {
    content: '';
    position: absolute;
    z-index: -1;
    top: -$size;
    right: -$size;
    bottom: -$size;
    left: -$size;
    display: block;
  }
}

@mixin manier {
  font-family: 'manier', serif;
}

@mixin brown {
  font-family: 'brown', sans-serif;
}

@mixin center-x {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

@mixin center-y {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

@mixin center-xy {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@mixin bg-color {
  @each $class, $color in $bg-colors {
    .#{$class} & {
      background-color: $color;
    }
  }
}
