
          $env: 'production';
          @import '/var/build/src/styles/utils/index';

        









































.single-job__cta {
  @include mq(m) {
    display: flex;
    justify-content: flex-end;
  }
}

.single-job__cta-big {
  @include mq(xl) {
    margin-top: 25rem;
  }
}
