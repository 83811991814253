/**
* Dev, based on $env (from gulp)
*/
$env: 'development' !default;

/**
 * Colors
 * for the naming use http://chir.ag/projects/name-that-color/
 * from darker to lighter ?
 */

// Global colors
$c-black: rgb(0, 0, 0);
$c-gray-darkest: rgb(32, 32, 32);
$c-gray-darker: rgb(64, 64, 64);
$c-gray-dark: rgb(96, 96, 96);
$c-gray-medium: rgb(111, 113, 117); // Used
$c-gray-light: rgb(160, 160, 160);
$c-gray-lighter: rgb(192, 192, 192);
$c-gray-lightest: rgb(239, 239, 239);
$c-white: rgb(255, 255, 255);
$c-transparent: transparent;

// Dev colors
$c-dev-bg: #3e4b55;
$c-dev-success: #090;
$c-dev-error: #d90b0b;
$c-dev-warning: #f50;
$c-dev-info: #829deb;
$c-dev-rebecca: rebeccapurple; /* stylelint-disable-line color-named */

// Project colors
$c-main-orange: #ff4b00;
$c-main-border: #5e5e5e;
$c-footer-bg: #141414;

$white: #fff;
$black: #000;
$alto: #d8d8d8;
$silver-chalice: #a2a2a2;
$gray: #818181;
$tango: #f08222;
$tango-dark: #e57019;
$iron: #d0d3d3;
$cornflower: #8db6e2;
$daisy-bush: #62259d;
$light-wisteria: #c49fd8;
$violet-eggplant: #9e1a96;
$salmon: #ff8671;
$razzmatazz: #e20559;
$mauvelous: #f18fa2;
$chalky: #eed797;
$school-bus-yellow: #fdd800;
$crown-of-thorns: #802528;
$cafe-royale: #79420f;
$my-sin: #ffb718;
$mango-tango: #de7c00;
$blaze-orange: #ff6b0b;
$monza: #e30513;
$cognac: #ad431c;
$green-smoke: #a1a466;
$yellow-green: #b7dc78;
$persian-green: #00a48c;
$orient: #00547e;
$zest: #e18532;
$mexican-red: #a12242;
$cocoa-brown: #321e1e;
$seashell-peach: #fff7ee;
$espresso: #653319;
$bone: #e7d9c9;

$bg-colors: (
  'orange': $blaze-orange,
  'orange-galler': $tango,
  'orange-dark': $mango-tango,
  'white': $c-white,
  'gray': $alto,
  'purple': $daisy-bush,
  'purple-light': $light-wisteria,
  'pink': $razzmatazz,
  'pink-dark': $violet-eggplant,
  'pink-light': $mauvelous,
  'salmon': $salmon,
  'blue': $orient,
  'blue-light': $cornflower,
  'turquoise': $persian-green,
  'yellow': $school-bus-yellow,
  'yellow-dark': $my-sin,
  'brown': $cafe-royale,
  'brown-light': $cognac,
  'red': $monza,
  'red-dark': $mexican-red,
  'green-paster': $green-smoke,
  'green-light': $yellow-green,
);

/**
* Wrapper
*/
$content-width: 1680px;

/**
* Typography
*/

// $base-font-size: 10px !default;

// $ff-default: 'roboto', sans-serif;
// $ff-alt: 'museo-sans-rounded', sans-serif;
// $body-text-s: 1.5em;
// $body-text-m: 1.6em;
// $body-text-l: 1.8em;

// $font-sizes: (
//   h1: 3.6rem,
//   h2: 2.8rem,
//   h3: 2.4rem,
//   h4: 1.4rem,
// );

/**
* RWD
*/
$mq-responsive: true;
$mq-static-breakpoint: 'large';
