
          $env: 'production';
          @import '/var/build/src/styles/utils/index';

        














.title {
  @include mq(m) {
    margin-right: inside-col(2);
  }
}

.links {
  flex: 1;
}

.link {
  cursor: pointer;
  @include fluid(
    font-size,
    (
      mob: 14px,
      xl: 16px,
    )
  );
  @include fluid(
    margin-bottom,
    (
      mob: 25px,
      xl: 50px,
    )
  );

  display: flex;
  justify-content: space-between;
  padding-bottom: 2.5rem;
  color: $c-black;
  font-weight: 400;
  line-height: 1;
  border-bottom: 1px solid $alto;

  ::v-deep {
    .icon {
      vertical-align: middle;
    }
  }
}
