.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.form-change-leave-active {
  transition: transform 2s, opacity 2s;
}

.form-change-inter,
.form-change-leave-to {
  opacity: 0;
  transform: translateX(-200px);
}

.form-change-enter-active {
  transition: transform 2s, opacity 2s;
}

.form-change-inter {
  opacity: 0;
  transform: translateX(200px);
}

.form-change-inter-to {
  transform: translateX(0);
  opacity: 1;
}
