
          $env: 'production';
          @import '/var/build/src/styles/utils/index';

        
















































.hero,
[class*='hero--'] {
  // prettier-ignore
  @include fluid(padding-top, (mob: 176px, xl: 350px));
  // prettier-ignore
  @include fluid(margin-bottom, (mob: 40px, xl: 80px));

  position: relative;
  overflow: hidden;
  color: $white;
  text-align: center;

  &[class*='--plain'] {
    // prettier-ignore
    @include fluid(padding-bottom, (mob: 40px, xl: 90px));

    margin-bottom: 0;
  }

  &[class*='--big-picture'] {
    margin-bottom: 0;
    padding-top: 0;
  }

  &.has-link {
    height: 70rem;
    max-height: 100vh;
  }
}

.hero__pattern {
  @include bg-color;
  @include get-all-space;

  height: 80%;
  background-color: $cognac;
  background-image: url('../assets/images/pattern.svg');
  background-position: 0% 50%;
  background-size: 100%;

  [class*='hero--'][class*='--plain'] &,
  [class*='hero--'][class*='--big-picture'] & {
    height: 100%;
  }

  [class*='hero--'][class*='--big-picture'] & {
    display: none;
  }
}

.hero__title,
.hero__baseline {
  [class*='hero--'][class*='--big-picture'] & {
    position: relative;
    z-index: 10;
  }
}

/* stylelint-disable-next-line no-descending-specificity */
.hero__title {
  margin-bottom: 3rem;

  [class*='hero--'][class*='--plain'] &,
  [class*='hero--'][class*='--big-picture'] & {
    margin-bottom: 0;
  }

  [class*='hero--'][class*='--big-picture'] & {
    // prettier-ignore
    @include fluid(font-size, (mob: 50px, xl: 160px));
    // prettier-ignore
    @include fluid(padding-top, (mob: 200px, xl: 665px));
    // prettier-ignore
    @include fluid(padding-bottom, (mob: 100px, xl: 200px));

    line-height: 1;
    // transform: translateY(20%);
    // opacity: 0;

    ::v-deep {
      /* stylelint-disable-next-line selector-max-combinators, selector-max-compound-selectors */
      div {
        opacity: 0;
      }
    }
  }

  [class*='hero--'][class*='--big-picture'].has-link & {
    padding-top: 0;
    padding-bottom: 0;
  }
}

.hero__link {
  z-index: 10;
}

.hero__content {
  @include bg-color;

  position: relative;
  z-index: 10;
  background-color: $cognac;
  background-image: url('../assets/images/pattern.svg');
  background-position: 0% 50%;
  background-size: 100%;

  [class*='hero--'][class*='--plain'] & {
    background: transparent;
  }

  [class*='hero--'][class*='--big-picture'] & {
    // prettier-ignore
    @include fluid(padding-top, (mob: 50px, xl: 120px));
    // prettier-ignore
    @include fluid(padding-bottom, (mob: 50px, xl: 120px));
  }

  .has-link & {
    display: none;
  }
}

/* stylelint-disable-next-line no-descending-specificity */
.hero__baseline {
  @include manier;

  font-size: 2.4rem;

  ::v-deep {
    p {
      font-family: inherit;
      font-size: inherit;
    }

    div {
      opacity: 0;
    }
  }

  [class*='hero--'][class*='--plain'] & {
    max-width: 84rem;
    margin-right: auto;
    margin-left: auto;
  }

  [class*='hero--'][class*='--big-picture'] & {
    padding-bottom: 0;
  }

  @include mq(m) {
    font-size: 3.6rem;
  }
}

.hero__image {
  position: relative;
  z-index: 1;
  width: 100%;
  max-width: 1680px;
  margin: 0 auto;
  padding: 0 col(2);

  [class*='hero--'][class*='--big-picture'] & {
    width: 100%;
    max-width: none;
    padding-right: 0;
    padding-left: 0;
  }

  .has-link & {
    // prettier-ignore
    @include fluid(padding-top,(xxs: 65px, xxl: 80px));

    display: flex;
    gap: 4rem;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
  }

  @include mq(xxxl) {
    padding: 0 210px;
  }
}

.hero__image__overlay {
  [class*='hero--'][class*='--big-picture'] & {
    @include get-all-space;

    z-index: 1;
    background: $c-black;
    opacity: 0.6;
  }

  .has-link & {
    display: none;
  }
}

.hero__image__pic {
  position: relative;
  overflow: hidden;

  &::before {
    content: '';
    display: block;
    width: 100%;
    padding-top: (9 / 16) * 100%;
  }

  img {
    @include image-fit;

    will-change: transform;
  }

  [class*='hero--'][class*='--big-picture'] & {
    img {
      transform: scale(1);
    }
  }

  [class*='hero--'][class*='--plain'] &,
  [class*='hero--'][class*='--big-picture'] & {
    position: static;

    &::before {
      display: none;
    }
  }
}
